import { createMuiTheme } from '@material-ui/core/styles';

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#59597bd4',
      light: '#6d6d6d',
      dark: '#1b1b1b',
    },
    secondary: {
      main: '#ffb300',
      light: '#ffe54c',
      dark: '#c68400',
    },
    appBar: {
    	main: '#393639b3'
    },
    appBarBottom: {
    	main: '#393639',
    },
    waveform: {
    	main: '#59597bd4'
    },
    infoBlue: {
      main: '#00acc1',
      light: '#5ddef4',
      dark: '#007c91'
    },
  },
});

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#80d8ff',
      light: '#b5ffff',
      dark: '#49a7cc',
    },
    secondary: {
      main: '#ffb300',
      light: '#ffe54c',
      dark: '#c68400',
    },
    text: {
    	light: '#969696',
    	dark: '#000000',
    },
    infoBlue: {
      main: '#00acc1',
      light: '#5ddef4',
      dark: '#7B00F4'
    },
  },
});

export const colorTheme01 = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#80d8ff',
      light: '#b5ffff',
      dark: '#49a7cc',
    },
    secondary: {
      main: '#ffb300',
      light: '#ffe54c',
      dark: '#c68400',
    },
    text: {
    	light: '#fff',
    	dark: '#000000',
    },
    panels: {
    	top: '#fafafa',
    	rightBox: '#eeeeee',
    	upperBox: '#eeeeee',
    	lowerBox: '#eeeeee'
    },
    infoBlue: {
      main: '#00acc1',
      light: '#5ddef4',
      dark: '#7B00F4'
    },
  },
});



export default darkTheme ;