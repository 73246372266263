import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../components/Firebase';
import { withAuthorization } from '../components/Session';
import { withStyles } from '@material-ui/core/styles';
import * as ROLES from '../constants/roles';
import ItemEditTable from '../components/ItemEditTable';
// import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {},
  spacing: {
  	margin: '5px 10px',
  	padding: '5px 5px'
  }
 });

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }	

	render() {
	  const { classes } = this.props;
		return (
			<div className={classes.spacing} >

				<ItemEditTable />

			</div>
		)
	}
};


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
	withStyles(styles),
	withAuthorization(condition),
	withFirebase,
)(AdminPage);