import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Todd from '../../static/todd-krupa-bio-pic.jpg';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
  },
  media: {
    height: 240,
  },
  heading: {
  	fontSize: 22,
  	textTransform: 'uppercase',	
  	marginBottom: theme.spacing(2),
  },
  details: {
  	fontSize: '.9rem',
  	marginBottom: theme.spacing(1),
  },
  songListContainer: {
  	marginTop: theme.spacing(4),
  },
  bioImage: {
  	display: 'block',
  	width: '80%',
  	borderRadius: '4px',
  },
}));

function BioContent () {
  const classes = useStyles();
  const theme = useTheme();

  return(
    <div style={{marginTop: theme.spacing(2) }} >
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={Todd}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Todd Krupa
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom={true} >
              Hailing from Portland, Oregon and residing in Brooklyn, Todd makes music with modular synthesizers and a modicum of custom made digital tools.  He's worked extensively with the UPA in NYC and continues to build interesting live sets that never sound the same twice.  Look for a new album coming in Fall 2021.  
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" >
              In addition to making digital instruments, Todd develops front end apps in React (such as this one), and programs backend architecture for companies in the music business.   
            </Typography>		          
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" href="mailto:info@autro-systems.com" >
            Contact
          </Button>
        </CardActions>
      </Card>				
    </div>
    )
  }
export default BioContent;