import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ROUTES from '../../constants/routes'
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
	root: {
		textAlign: 'center',
		marginTop: theme.spacing(4),
	},
}));

function ThankYouPage() {
	const classes = useStyles();

	return (
			<Box className={classes.root} >
				<Typography variant="h4" gutterBottom={true} >
					Thank you for contributing!
				</Typography>
				<Typography paragraph={true} >
					Your card was charged successfully, and you now have access to the content here.
				</Typography>
				<Button variant="outlined" component={Link} to={ROUTES.home.slug} >
					Go to the music
				</Button>
			</Box>
		)
};

export default ThankYouPage;
