import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './form.js';

// set public key
const stripePromise = loadStripe('pk_test_lAd6EylNIiCeAETm10O9kcGZ');
// set the secret key in firebase config https://github.com/firebase/functions-samples/tree/main/stripe#deploy-and-test

const Checkout = (props) => {

	const authUser = props.authUser;

	return (
	  <Elements stripe={stripePromise} >
  		<CheckoutForm authUser={authUser} />
	  </Elements>
	)
}

export default Checkout;
