import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { withStyles } from "@material-ui/core/styles";
import { WaveformContianer, Wave } from './Wavesurfer.styled';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
  root: {},
  progressBar: {
  	position: 'absolute',
  	width: '100%',
  },
  statusMessage: {
  	position: 'absolute',
  	margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  hint: {
  	width: '100%',
  	height: '110px',
  	paddingTop: theme.spacing(5),
  	position: 'absolute',
  	textAlign: 'center',
  	color: theme.palette.text.primary,
  	zIndex: 100,
  	pointerEvents: 'none',
  	backgroundColor: '#0000008c',
  },
  wavebox: {
  },
});

class Waveform extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: this.props.url,
    };
  }

  componentDidMount(props) {
  	    this.waveform = WaveSurfer.create({
        barWidth: 3,
        cursorWidth: 1,
        container: '#waveform',
        backend: 'WebAudio',
        height: 100,
        progressColor: this.props.theme.palette.waveform.main,
        responsive: true,
        waveColor: '#EFEFEF',
        cursorColor: 'transparent',
        playing: false

      });
  		  this.waveform.load(this.props.url);	
  };

	componentDidUpdate(prevProps) {
		
		if ( prevProps.playing !== this.props.playing ) {
			this.waveform.playPause();
				this.waveform.on('finish',  () => {
	      this.props.nextTrack()
	    })		
		}

	  if (prevProps.url !== this.props.url) {
	  	this.setState({loading: true});
	  	// this.waveform.destroy(prevProps.url);
		  this.waveform.load(this.props.url);
		  this.waveform.backend.ac.resume() 
		  this.waveform.on('ready', 
		  this.waveform.play.bind(this.waveform));
		  setTimeout(() => {  this.setState({loading: false}); }, 1000);
		}

	};

	componentWillUnmount() {
		this.waveform.destroy();
	};
  
  handlePlay = () => {
    // this.setState({ playing: !this.state.playing });
    this.waveform.playPause();
  };

  handleWaveClick = () => {
  	this.props.waveClick()
  }
  
  render() {
    const { classes, playing, paywall, firstVisit } = this.props;
    const { loading } = this.state;
    return (
    	<div>
    	{loading ? <LinearProgress className={classes.progressBar} /> : '' }
    	<Fade in={ !playing || firstVisit } >
	    	<Typography className={classes.hint} > 
	    		{paywall ? 'Soft paywall: Tap wave to resume' : 'Tap an album to listen'}
	    	</Typography>	
	    </Fade>
	    	<div className={classes.wavebox} >
      <WaveformContianer onClick={this.handleWaveClick} >	
        <Wave id="waveform" />
        <audio id="track" url={this.state.url} />
      </WaveformContianer>
      	</div>
      </div>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Waveform);