import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Backdrop from '@material-ui/core/Backdrop';
import ItemEditSingle from '../ItemEditSingle';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {},
	  backdrop: {
	  zIndex: theme.zIndex.drawer + 1,
	  color: '#fff',
	  backgroundColor: "rgb(0 0 0 / 92%)",
	  overflow: 'auto',
	},
	editContainer: {
		width: '50%',
		margin: 'auto'
	},
	divide: {
		margin: '20px 0px'
	}
});

class ItemEditTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      albums: [],
      album: {},
      editId: '',
      open: false,
      currentAlbum: {
      	title: '',
      	publishDate: '',
      	tracks: {}
      },
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .getCollection('albums')
      .onSnapshot(snapshot => {
        let albums = [];

        snapshot.forEach(doc =>
          albums.push({ ...doc.data(), id: doc.id }),
        );

        this.setState({
          albums,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleNewItem = () => {
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + '-' + mm + '-' + dd;

  	this.props.firebase
  	.getCollection('albums')
  	.add({
  		albumTitle: 'Untitled Album',
  		publishDate: today,
  		tracks: [
  		{
  			title: '',
  			url: '',
  		}]
  	})
  }

  handleCellClick = ( event ) => {
  	this.setState({editId: event.row.id})
  	if ( event.row.id.length !== 0 ) {
  		 this.setState({
  		 	currentAlbum: this.state.albums.find(x => x.id === this.state.editId), 
  		 	open: true 
  		 })
  	}
  }

  handleClose = ( event ) => {
  	this.setState( { open: false } )
  }

  render() {
    const { albums, open, currentAlbum } = this.state;
    const { classes } = this.props; 
    const columns = [
  	{ field: 'albumTitle', headerName: 'Album Title', flex: 1 },
  	{ field: 'publishDate', headerName: 'Date', width: 200, },
  	]


  	const rows = albums.map( album => ({ //each album 
  		id: album.id, 
  		albumTitle: album.albumTitle,
  		publishDate: album.publishDate,
  		tracks: album.tracks,
  	}));
  	

    return (
    	    <Grid style={{ height: 800, width: '100%' }}>
    	    	<Grid style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }} >
    	    		<h1>Overview of Items</h1>
    	    		<Button style={{alignSelf: 'flex-end'}} onClick={ (event) => this.handleNewItem()}>New Item</Button>
    	    	</Grid>
    	    	
      			<DataGrid 
      			rows={rows} 
      			columns={columns} 
      			pageSize={5} 
    				onCellClick={(event) => this.handleCellClick(event)  }
    				hideFooterSelectedRowCount={true}
    				hideFooterPagination={true}
    				hideFooterRowCount={true}
    				autoHeight={true}
    				hideFooter={true}
						sortModel={[
						    { field: 'publishDate',sort: 'desc' },
						  ]}
      			/>
					<Backdrop className={classes.backdrop} open={open}>
						<Grid container className={classes.editContainer} >
							<Grid item xs={12}>
								<ItemEditSingle currentAlbum={currentAlbum} />
							</Grid>

							<Grid item xs={12} className={classes.divide}>
								<Button variant={'outlined'} onClick={ (event) => this.handleClose(event)}>Done</Button>
							</Grid>
							</Grid>
					</Backdrop>
    			</Grid>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(styles)
)(ItemEditTable);