import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Modular from "assets/modular.jpg";
import Clouds from "assets/clouds.jpg";

const useStyles = makeStyles((theme) => ({
  diagonalBox: {
    position: "relative",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  contentArea1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    clipPath: "polygon(0 0, 100% 0, 0 100%)",
    background: "white",
    color: "black", // Add text color
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${Clouds})`,
    "&:hover": {
      backgroundImage: `linear-gradient(rgba(64, 224, 208, 0.8), rgba(64, 224, 208, 0.8)), url(${Clouds})`,
    },
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  contentArea2: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    clipPath:
      "polygon(100% 0, 100% 100%, 0 100%)",
    background: "black",
    color: "white", // Add text color
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Modular})`,
    "&:hover": {
      backgroundImage: `linear-gradient(rgba(100, 0, 100, 0.8), rgba(100, 0, 100, 0.8)), url(${Modular})`,
    },
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  shift1: {
    transform: "translate(-20vw, -20vh)",
  },
  shift2: {
    transform: "translate(20vw, 20vh)",
  },
}));

export default function NavDialog() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] =
    React.useState(true);

  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();

    img1.src = Clouds;
    img2.src = Modular;

    Promise.all([
      new Promise((resolve, reject) => {
        img1.onload = resolve;
        img1.onerror = reject;
      }),
      new Promise((resolve, reject) => {
        img2.onload = resolve;
        img2.onerror = reject;
      }),
    ])
      .then(() => setLoading(false))
      .catch((err) =>
        console.error(
          "Failed to load images",
          err
        )
      );
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const matchesSm = useMediaQuery(
    theme.breakpoints.down("sm")
  );
  const textVariant = matchesSm ? "body1" : "h4";

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
    >
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.diagonalBox}>
          <Box
            className={classes.contentArea1}
            onClick={() =>
              window.open(
                "https://developer.toddkrupa.com",
                "_blank"
              )
            }
          >
            <Box className={classes.shift1}>
              <Typography variant={textVariant}>
                <strong>Todd Krupa</strong>
                <em
                  style={{ fontSize: "smaller" }}
                >
                  , n.
                </em>{" "}
                <br />
                <Typography variant="body1">
                  /<em>ˈtɒd ˈkruːpə</em>/
                </Typography>
                <Typography variant="body1">
                  A{" "}
                  <b
                    style={{ fontSize: "larger" }}
                  >
                    software developer
                  </b>
                  .
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box
            className={classes.contentArea2}
            onClick={handleClose}
          >
            <Box className={classes.shift2}>
              <Typography variant={textVariant}>
                <strong>Todd Krupa</strong>
                <em
                  style={{ fontSize: "smaller" }}
                >
                  , n.
                </em>{" "}
                <br />
                <Typography variant="body1">
                  /<em>ˈtɒd ˈkruːpə</em>/
                </Typography>
                <Typography variant="body1">
                  A{" "}
                  <b
                    style={{ fontSize: "larger" }}
                  >
                    music producer
                  </b>
                  .
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Dialog>
  );
}
