import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Button, Typography } from '@material-ui/core';
import Home from './Home';

const useStyles = makeStyles(theme => ({
  root: {},
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      margin: `${theme.spacing(4)}px auto ${theme.spacing(20)}px auto`
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1)}px 
  		${theme.spacing(1)}px 
  		${theme.spacing(20)}px 
  		${theme.spacing(1)}px`
    },
  },
  submitButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

function PreviewArea(props) {

  const classes = useStyles();
  const [authed, setAuthed] = useState(false);
  const [error, setError] = useState(false);


  return (
    <div>
      <Formik
      initialValues={{password: ''}}
      onSubmit={(values, {setSubmitting}) => {
        if (values.password === 'soundboyfree'){
          setSubmitting(false);
          setAuthed(true);
        } else {
          setError("Sorry, that password isn't valid")
        }
      }}>
        <Box className={classes.formContainer}
        hidden={authed} >
          <Form>
            <Field name="password"
            label={'Password'}
            disabled={false}
            type="password"
            component={TextField} />
            <Button type="submit"
            variant="contained"
            className={classes.submitButton}
            >
              Submit
            </Button>
            <Typography>
              {error && (error)}
            </Typography>
          </Form>
        </Box>
      </Formik>
      
        { authed && (<Home {...props} secretPreview={authed} />) }
      
      
    </div>
  )

}

export default PreviewArea;