import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const styles = theme => ({
  root: {},
  answerContainer: {
  	display: 'flex',
  	justifyContent: 'center'
  },
  surveyButton: {
  	margin: '0px 15px'
  }
});

class SurveyItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      surveyItems: [],
      needsSubmit: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .getCollection('currentQuestion').doc('currentDoc')
      .onSnapshot(doc => {
        const surveyItems = doc.data().answers;
        const surveyQuestion = doc.data().question;
        const qid = doc.data().id;
        const person = 
  	      JSON.parse(localStorage.getItem('authUser'))['firstName'] 
		        + " " +
		      JSON.parse(localStorage.getItem('authUser'))['lastName'] 
        this.setState({
          surveyItems,
          surveyQuestion,
          qid,
          person, 
          loading: false,
          needsSubmit: true,
          thankYou:false
        });
      });
  }
  
  componentWillUnmount() {
    this.unsubscribe();
  }

  handleClick = (event, surveyItem, person, qid) => {
  	surveyItem['person'] = person;
  	surveyItem['timeSubmitted'] = new Date().toUTCString();
  	surveyItem['qid'] = qid;
  	this.props.firebase
  	.db.collection('answers')
  	.add(surveyItem);
  	this.setState({
  		needsSubmit: false,
  	})
  }

  render() {
    const { surveyItems, surveyQuestion, person, qid, needsSubmit } = this.state;
    const { classes } = this.props;   

    return (
    	<div>
	    	<Fade in={needsSubmit}>
		      <div>
		      	<h3>{surveyQuestion}</h3>
			        <div className={classes.answerContainer} >
			          { Object.keys(surveyItems).map(key => {
			          	 return (
			 	          	 <Button 
			 	          	 	key={surveyItems[key].answer} 
			 	          	 	variant="outlined"
			 	          	 	className={classes.surveyButton} 
				            	onClick={(e) => {
											  this.handleClick(e, surveyItems[key], person, qid)
											}}	 	          	 	
										 >
			 	          	 	{surveyItems[key].answer}
			 	          	 </Button>
			 	          	)
									})
			          }
			        </div>
		      </div>
		    </Fade>
		    <Fade in={!needsSubmit} >
		    	<div style={{marginTop: '-70px'}} className={classes.root} >
		    	<CheckCircleOutlinedIcon color="primary" fontSize="large" />
		    	<h3>Thanks, got it!</h3>
		    	</div>
		    </Fade>
		  </div>
    );
  }
};

export default compose(
  withFirebase,
  withStyles(styles)
)(SurveyItems);