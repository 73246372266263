const config = {
	apiKey: "AIzaSyAevfKEFVssF5qaTkTrthUypKC8yC0GNVI",
    authDomain: "react-02-81067.firebaseapp.com",
    databaseURL: "https://react-02-81067.firebaseio.com",
    projectId: "react-02-81067",
    storageBucket: "react-02-81067.appspot.com",
    messagingSenderId: "550138236670",
    appId: "1:550138236670:web:5c85792a05c82bf3645faf"
};

export default config;