import React from 'react';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import SignOutButton from '../Firebase/signout'

const useStyles = makeStyles(theme => ({
    menuListItemIcon: {
      minWidth: 45,
  },
}));


const Navigation = (props) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} props={props} />
      ) : (
        <NavigationNonAuth props={props} />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser, props }) => {

  const classes = useStyles();

  return (
  <div>
      <MenuItem button component={Link} to={ ROUTES.home.slug } onClick={props.close} >
         <ListItemText primary={ ROUTES.home.title }/>
      </MenuItem>

    {!!authUser.roles[ROLES.ADMIN] && (
      <MenuItem button component={Link} to={ ROUTES.admin.slug } onClick={props.close}  >
         <ListItemText primary={ ROUTES.admin.title }/>
      </MenuItem>
    )}
    <SignOutButton space={classes.menuListItemIcon} />
      <MenuItem button component={Link} to={ ROUTES.signup.slug } onClick={props.close}>
         <ListItemText primary={ ROUTES.signup.title } />
      </MenuItem>
  </div>
  );

};


const NavigationNonAuth = ({props}) => (

    <div>
      <MenuItem button component={Link} to={ ROUTES.home.slug } onClick={props.close}>
         <ListItemText primary={ ROUTES.home.title }/>
      </MenuItem>
      <MenuItem button component={Link} to={ ROUTES.signin.slug } onClick={props.close}>
         <ListItemText primary={ ROUTES.signin.title } />
      </MenuItem>
      <MenuItem button component={Link} to={ ROUTES.signup.slug } onClick={props.close}>
         <ListItemText primary={ ROUTES.signup.title } />
      </MenuItem>
      <MenuItem button component={Link} to={ROUTES.secret.slug} onClick={props.close}>
        <ListItemText primary={ROUTES.secret.title} />
      </MenuItem>
    </div>


);

export default Navigation;
