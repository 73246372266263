import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import { ChatFeed }  from 'react-chat-ui';
import ChatSubmit from './chatSubmit';
import Box from '@material-ui/core/Box';

const styles = theme => ({
  root: {},
  });

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messages: [],
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .getCollection('messages')
      .orderBy("curTime")
      .onSnapshot(snapshot => {
        let messages = [];
        snapshot.forEach(doc =>
          messages.push({ ...doc.data(), id: doc.id }),
        );
        this.setState({
          messages,
          loading: false,
      });
    });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }


  render() {
    return (
          <Box >
              <Box >
                <ChatFeed
                messages={this.state.messages}
                theID={this.props.theID} // Array: list of message objects
                maxHeight="80vh"
                isTyping={this.state.is_typing} // Boolean: is the recipient typing
                hasInputField={false} // Boolean: use our input, or use your own
                showSenderName // show the name of the user who sent the message
                bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                // JSON: Custom bubble styles
                bubbleStyles={
                 {
                   text: {
                     fontSize: 15,
                     color: this.props.theme.palette.text.light
                   },
                   chatbubble: {
                     borderRadius: 6,
                     padding: '0 15',
                     backgroundColor: this.props.theme.palette.primary.dark
                   }
                 }}
                />
              </Box>


            <Box position="sticky" width="100%" bottom="0" className="submitBox" padding="0">
              <ChatSubmit theID={this.props.theID} />
            </Box>
          </Box>
      )
  }
}


export default compose(
  withStyles(styles, { withTheme: true }),
  withFirebase,
)(Chat);
