import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

// import * as ROLES from '../../constants/roles';

const useStyles = makeStyles(theme => ({
	root: {},
	checkoutFormContainer:{ 
		maxWidth: '100%',
		margin: '40px auto',
	},
	cardElementContainer: {
		borderBottom: `1px solid ${theme.palette.text.secondary}`,
		paddingBottom: '8px'
	},
	submit: {
		marginTop: theme.spacing(4),
		color: theme.palette.text.secondary,
	},
	stripeExplanation: {
		fontSize: '.7rem',
		marginTop: theme.spacing(2),
	}
}));

const CheckoutForm = (props) => {
	const classes = useStyles();
	const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

	function stripeDumpHandler(token, paymentMethod) {

		const userEmail = props.authUser.email;

	  const paymentData = {
	  	token: token.id,
	  	amount: 600,
	  	currency: 'USD',
	  	payment_method: paymentMethod.id,
	  	receipt_email: userEmail
	  };

	  const roles = {
	  	PAID: 'PAID',
	  }

		props.firebase.db.collection('stripe_customers')
		.doc(props.authUser.uid).collection('payments').add(paymentData)
			.then(authUser => {
				props.firebase.db.collection('users')
				.doc(props.authUser.uid)
				.set({roles}, {merge: true})
	      .catch(error => {
	        console.log(error);
	      })				
				.then(
					props.history.push(ROUTES.thankyou.slug)
					)
				.then(
					window.location.reload())
      })		

	  // Return and display the result of the charge.
	  return ;
	}


  const handleSubmit = async (event) => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (card == null) {
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      // console.log('[PaymentMethod]', paymentMethod);
      stripeDumpHandler(result.token, paymentMethod);
    }
  };

	return (
	  <div>

	    <Formik
	      initialValues={{
	        firstName: '',
	        lastName: '',
	        email: '',
	      }}
	      onSubmit={ handleSubmit }
	    >
	      <Form className={classes.checkoutFormContainer} >
	        
	        <div className={classes.cardElementContainer}>
            <CardElement
              options={{
              style: {
                    base: {
                      iconColor: "#fff",
                      color: theme.palette.text.primary,
                      fontWeight: 400,
                      fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                      fontSize: "16px",
                      fontSmoothing: "antialiased",

                      "::placeholder": {
                        color: theme.palette.text.disabled
                      },
                      ":-webkit-autofill": {
                        color: "#fce883"
                      }
                    },
                    invalid: {
                      iconColor: "#FFC7EE",
                      color: "#FFC7EE"
                    }
                  }
                }}	       
              />
					</div>	
	        <Button type="submit" 
	        variant="outlined"
	        className={classes.submit} >
	        	Make Payment
	        </Button>
	        <Typography className={classes.stripeExplanation} >
	        	Payments are handled by Stripe and are 100% secure (we'll never even see your card info).
	        </Typography>
	      </Form>
	    </Formik>
	  </div>
	)
};

// const condition = authUser =>
//   authUser && !!authUser.roles[ROLES.SUBSCRIBER];

export default compose(
	withRouter,
	withFirebase,
)( CheckoutForm );