import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
  root: {},
  submitContainer: {
    alignItems: 'flex-end',
  },
  sendButton: {
    color: theme.palette.secondary.main
  },
  enterChat: {
  	color:theme.palette.infoBlue.light
  }
});

const INITIAL_STATE = {
      message: '',
      curTime: '',
      error: null,}
 
class ChatSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE} ;
  }
 
  onSubmit = event => {
    event.preventDefault();
    // const { message, senderName } = this.state;

    // this.setState({senderName: this.props.firebase.db.collection("users").doc()})
 
    this.props.firebase
      .db.collection("messages")
      .add(this.state)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });


  };
 
  onChange = event => {

    this.setState({ [event.target.name]: event.target.value });
  };

  onClick = event => {
    this.setState({
      senderName: JSON.parse(localStorage.getItem('authUser'))['firstName'] 
        + " " +
      JSON.parse(localStorage.getItem('authUser'))['lastName']
    },
    )

  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.setState({
        curTime : new Date()
      })
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }  
 
  render() {
    const { message, error } = this.state;
    const { classes } = this.props;

    return (
      
        <form onSubmit={this.onSubmit} >
          <Grid container className={classes.submitContainer}>
                <Grid item xs={10}>
                  <TextField id="message" label="Type a chat message:"
                  	color="secondary"
                    width="100%"
                    name="message"
                    value={message}
                    onChange={this.onChange}
                    onClick={this.onClick}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton variant="outlined" type="submit" className={classes.sendButton} >
                    <SendIcon />
                  </IconButton>
                </Grid>
                  {error && <p>{error.message}</p>}
          </Grid>
        </form>
      
    );
  }
}
 
 
export default compose(
  withStyles(styles),
  withFirebase,
)(ChatSubmit);