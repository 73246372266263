import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext, Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import debounce from 'just-debounce-it';
import { withFirebase } from '../Firebase';
import Divider from '@material-ui/core/Divider';
// import format from 'date-fns/format';
// import isValid from 'date-fns/isDate'
// import toDate from 'date-fns/toDate'
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Switch } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
	root: {},
	addTrackButton: {
		marginTop: theme.spacing(2)
	},
	deleteAlbumButton: {
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		color: '#FF0000'
	}
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const validate = values => {

  return sleep(300).then(() => {
    const errors = {};

    if (['admin', 'null', 'god'].includes(values.albumTitle)) {
      errors.username = 'Nice try';
    }

    if (Object.keys(errors).length) {
      throw errors;
    }
  });
};

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext();
  const [lastSaved, setLastSaved] = React.useState(null); // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() =>
        formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  );

  React.useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <>
      {!!formik.isSubmitting
        ? 'saving...'
        : lastSaved !== null 
        ? `Last Saved: ${lastSaved}`
        : null}
    </>
  );
};

const AutoSavingForm = props => {

    const classes = useStyles();

    const handleDelete = albumId => {
    props.firebase.db.collection("albums").doc(albumId)
    .delete().then(() => {
        console.log("Document successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });

  }
	return (
	  <div>
	    <Formik
	    	enableReinitialize={true}
	      initialValues={{ 
	      	artist: props.currentAlbum.artist || '' ,
	      	albumTitle: props.currentAlbum.albumTitle || '', // fixes "A component is changing an uncontrolled input" error 
	      	publishDate: props.currentAlbum.publishDate || '',
	      	coverArt: props.currentAlbum.coverArt || '',
	      	linerNotes: props.currentAlbum.linerNotes || '',
	      	previewMode: props.currentAlbum.previewMode || '',
	      	tracks: props.currentAlbum.tracks || [
	      		{url: '', title: ''}
	      	]
	      }}
	      validate={validate}
	      onSubmit={(values, { setSubmitting }) => {
	      	if ( props.currentAlbum.id ){
		        return new Promise(resolve =>
		          setTimeout(() => {
		          props.firebase 
			        .db.collection("albums").doc( props.currentAlbum.id ) 
			        .set(values) 
			        .catch(error => {

			        }); 
		            setSubmitting(false);
		            resolve();
		          }, 1000)
		        );
      			}}}>
		        {({ values }) => (
	            <Form>
	              <h1>
	                <small style={{ color: 'gray', fontSize: 11 }}>
	                  <AutoSave debounceMs={300} />
	                </small>
	              </h1>

	              <Field name="artist"
	              	label={'Artist'}
	              	disabled={false}
	              	component={TextField}  />

	              <Field name="albumTitle"
	              	label={'Title'}
	              	disabled={false}
	              	component={TextField}  />
	    
	              <Field name="publishDate"
	              	label="Release Date"
	              	disabled={false}
	              	type={'date'}
	              	component={TextField}  />

	              <Field name="coverArt"
	              	label="Cover Art"
	              	disabled={false}
	              	component={TextField}  />	

	              <Field name="linerNotes"
	              	label="Liner notes"
	              	disabled={false}
	              	component={TextField}
	              	multiline
	              	rows={4}
	              	rowsMax={18}  />	 	              	           	

	              <FieldArray name="tracks">
	              {({ insert, remove, push }) => (
	                <div>
	                  {values.tracks.length > 0 &&
	                    values.tracks.map((album, index) => (
	                      <Grid container key={index} style={{alignItems: 'flex-end'}} >
	                        <Grid item xs={11}>
	                        <Divider />
	                          <Field
	                            component={TextField}
	                            disabled={false}
	                            name={`tracks.${index}.title`}
	                            label={`Track ${index+1} title`}
	                            type="text"
	                          />
	                          <Field
	                            component={TextField}
	                            disabled={false}
	                            name={`tracks.${index}.url`}
	                            label={`Track ${index+1} url`}
	                            type="text"
	                          />	                         
	                          <ErrorMessage
	                            name={`tracks.${index}.track`}
	                            component="div"
	                            className="field-error"
	                          />
	                        <Divider />
	                        </Grid>
	                        <Grid item xs={1} >
	                          <IconButton
	                            type="button"
	                            className="secondary"
	                            onClick={() => remove(index)}
	                          >
	                            <HighlightOffIcon />
	                          </IconButton>                        
	                        </Grid>
	                      </Grid>
	                    ))}
		                    <Grid container style={{ display: 'flex', marginBottom: 10 }} >
				                  <Button
				                    type="button"
				                    className={classes.addTrackButton}
				                    onClick={() => push({ title: '', url: '' })}
				                  >
				                    Add Track
				                  </Button>
				                  <Button
				                    type="button"
				                    className={classes.deleteAlbumButton}
				                    onClick={( event ) => handleDelete(props.currentAlbum.id) }
				                  > 
				                    Delete Entire Album
				                  </Button>
				                </Grid>
	                </div>
	              )}
	            </FieldArray>
		            <Typography>
            			Preview Mode
            		</Typography>
                  <Field
                  	component={Switch}
                  	type="checkbox"
                  	disabled={false}
						        // checked={props.previewMode}

						        name="previewMode"
						        label="previewMode"
						        // inputProps={{ 'aria-label': 'secondary checkbox' }}
						      />
              	<ErrorMessage name="username" />
	            </Form> 
	          )}
      </Formik>
	  </div>
	)
};

export default withFirebase( AutoSavingForm );