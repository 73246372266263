import React from 'react';
import HomePage from '../pages/Home'
import AdminPage from '../pages/Admin';
import SignUpPage from '../components/SignUp';
import SignInPage from '../components/SignIn';
import ResponsivePlayer from '../pages/Player';
import Checkout from '../components/Checkout';
import ThankYouPage from '../components/Checkout/thankyou'
import PreviewArea from '../pages/PreviewArea';

const ROUTES = {
			signup: {
				title: 'Sign Up',
				slug: '/signup',
				component: <SignUpPage />
			},
			signin: {
				title: 'Sign In',
				slug: '/signin',
				component: <SignInPage />
			},
			admin: {
				title: 'Admin',
				slug: '/admin',
				component: <AdminPage />
			},
			home: {
				title: 'Home',
				slug: '/',
				component: <HomePage />
			},
			player: {
				title: 'Player',
				slug: '/player',
				component: <ResponsivePlayer />,
			},
			checkout: {
				title: 'Buy In',
				slug: '/checkout',
				component: 	  <Checkout />
			},
			thankyou: {
				title: 'Thank You',
				slug: '/thank-you',
				component: 	  <ThankYouPage />
			},
      secret: {
        title: 'Secret Preview',
        slug: '/secret-preview',
        component: <PreviewArea />
      }			

		}

export default ROUTES;