import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import DownloadLink from '../DownloadLink';

const useStyles = makeStyles(theme => ({
  root: {
	  '&.Mui-selected, &.Mui-selected:hover': {
	    backgroundColor: theme.palette.primary.main
	  }
  },
  heading: {
  	fontSize: 24,
  	marginBottom: theme.spacing(2),
  },
  details: {
  	fontSize: '.9rem',
  	marginBottom: theme.spacing(1),
    fontStyle: 'italic'
  },
  songListContainer: {
  	marginTop: theme.spacing(4),
  },
}));

function InfoContent (props) {
  const classes = useStyles();
  const theme = useTheme();
  const album = props.currentAlbum
  const { playing, authUser } = props; 
  const dense = true;
  const page = 0;
  const rows = 10;
  const rowsPerPage = 10;
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	const handleClickSong = props.handleClickSong

	return (
		<div style={{marginTop: theme.spacing(2) }} >
			<Typography variant="h1" className={classes.heading} >
				{props.currentAlbum.albumTitle}
			</Typography>
			<Typography variant="body1" className={classes.details} >
				{props.currentAlbum.publishDate}
			</Typography>		
			<Typography variant="body1" className={classes.details} >
				{ `${props.currentAlbum.artist}` }
			</Typography>					
			<Typography variant="body2" className={classes.description} >
				{props.currentAlbum.linerNotes}
			</Typography>

				<TableContainer className={classes.songListContainer} >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <TableBody>
              { album.tracks ? album.tracks.map( (track, index) => {
								//go check each song in render for matching url and album (for selection)
                var isItemSelected = props.selected === index ;
                const labelId = `enhanced-table-checkbox-${track.index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClickSong(event, album.tracks, track.url, album, album.albumTitle)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={track.title}
                      selected={isItemSelected}
                      className={classes.root}
                      >
                      <TableCell width={25} className={classes.iconTableCell} >
                      	{ isItemSelected && playing ? <PauseCircleOutlineIcon className={classes.playButton} /> 
                      	: <PlayCircleOutlineIcon className={classes.playButton} /> }
                      </TableCell>
                      <TableCell id={labelId} scope="row" >
                        {track.title}
                      </TableCell>

                     </TableRow>
                  );
                })
                : null 
              }
              { emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              </TableBody>
          </Table>
      </TableContainer>
      { authUser && authUser.roles.PAID === 'PAID' 
        ? <DownloadLink file={props.currentAlbum.albumTitle + '.zip'} /> 
        : '' 
      }
      

		</div>
		)
} 

export default InfoContent;