import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from '../components/Firebase';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoContent from '../components/InfoContent';
import BioContent from '../components/BioContent';

const styles = theme => ({
  root: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  box: {
    minHeight: `calc(100vh - 94px)`,
    padding: '0px 30px'
  },
  albumTitle: {
    marginLeft: 20,
  },
  imageContainer: {
    textAlign: 'center',
    backgroundColor: 'black',
    border: 'none',
    margin: '10px 10px 5px 10px',
  },
  infoContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  infoPaper: {
    position: 'fixed',
    minHeight: '33vh',
    width: '30%',
    margin: '16px 24px 16px 16px',
    backgroundColor: 'transparent',
  },
  gridContainerAll: {
    justifyContent: 'center'
  },
  gridContainerAlbums: {
    marginBottom: 150,
    flexBasis: '66%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      justifyContent: 'center'
    },
  },
  gridItem: {
    flexBasis: 'unset',
  },
  card: {
    margin: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(2),
    },
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  media: {
    margin: 'auto',
    backgroundColor: '#000',
    maxWidth: '100vw',
  },
  mediaOverlay: {
    position: 'absolute',
    maxWidth: '100vw',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    margin: 'auto',
    backgroundColor: '#000',
    padding: 90,
    opacity: 0,
    '&:hover': {
      opacity: .7
    },
  },
  cardContent: {
    backgroundColor: '#424242',
    padding: '0px !important'
  },
  postCardList: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  playButton: {
    verticalAlign: 'bottom',
  },
  iconTableCell: {
    paddingRight: 0,
  },
  loader: {
    margin: '25vh auto',
    [theme.breakpoints.up('md')]: {
      margin: '25vh'
    },
  },
  title: {
    fontSize: '1rem',
    fontWeight: 300,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1) - 2,
    marginBottom: theme.spacing(1) - 2,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
});


class HomePage extends Component {


  constructor(props, prevProps) {
    super(props);
    this.state = {
      loading: false,
      albums: [],
      album: {
        id: '',
        albumTitle: '',
        publishDate: '',
        tracks: []
      },
      selected: [],
      trackNumber: 0,
    };
  }


  componentDidMount(props) {
    this.setState({ loading: true });
    this.props.secretPreview && (this.props.previewFunction(true))
    this.unsubscribe = this.props.firebase
      .getCollection('albums')
      .orderBy('publishDate', 'desc') // can we pop a variable in here?
      .onSnapshot(snapshot => {
        let albums = [];
        snapshot.forEach(doc =>
          albums.push({ ...doc.data(), id: doc.id }),
        );
        this.setState({
          albums,
          loading: false,
        });
      });
  };


  componentDidUpdate(prevProps) {
    if (this.props.trackNumber !== prevProps.trackNumber) {
      this.setState({ selected: this.props.trackNumber })
    }
  }


  componentWillUnmount() {
    this.unsubscribe();
  }


  handleClickAlbum = (event, album) => {
    this.props.selectedTrack(album.albumTitle.replace(/\s+/g, '-').toLowerCase() + '/' + album.tracks[0].url, 0, album, event);
    this.setState({ selected: 0, album: album });
  }


  handleClickSong = (event, list, url, album, albumTitle) => {
    //url is filename --- add files to a lowercase, hyphenated album folder in public/audio (with the same name as album)
    var selected = list.map(function (e) { return e.url; }).indexOf(url);
    this.props.selectedTrack(albumTitle.replace(/\s+/g, '-').toLowerCase() + '/' + url, selected, album); //callback to drawer


    this.setState({ selected: selected, album: album });
  }



  render() {
    const { albums, selected, loading } = this.state;
    const { classes, playing, authUser, secretPreview } = this.props;
    const albumsFiltered = secretPreview ? (albums.filter(album => album.secretPreview === true)) : authUser && authUser.roles.ADMIN === 'ADMIN' ? albums : albums.filter(album => album.previewMode !== true);
    const currentAlbum = this.state.album;
    const dense = true;
    const page = 0;
    const rows = 10;
    const rowsPerPage = 10;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const renderSelected = (current, index, albumId) => {
      if (typeof (current) !== 'undefined') {
        return current.url === index && albumId === this.state.album.id;
      }
      else return false;
    }


    return (
      <Grid container className={classes.gridContainerAll}>
        <Grid item style={{ width: '33%' }} className={classes.infoContainer} >
          <div>
            <Fade timeout={2000} in={!loading} >
              <Paper className={classes.infoPaper} elevation={0} >
                {currentAlbum.id ?
                  <InfoContent
                    currentAlbum={currentAlbum}
                    handleClickSong={this.handleClickSong}
                    playing={playing}
                    selected={selected}
                    authUser={this.props.authUser}
                  />
                  :
                  <BioContent />
                }
              </Paper>
            </Fade>
          </div>
        </Grid>
        <Grid container item className={classes.gridContainerAlbums}>
          {loading ? <CircularProgress size={80} className={classes.loader} /> :
            albumsFiltered.map(album => (
              <Grid item xs={11} sm={6} key={album.id} className={classes.gridItem} >
                <Card key={album.id} className={classes.card}>
                  {currentAlbum.id === album.id && playing ?
                    <PauseCircleOutlineIcon
                      className={classes.mediaOverlay}
                      onClick={(event) => this.handleClickAlbum(event, album)}
                    />
                    :
                    <PlayCircleOutlineIcon
                      className={classes.mediaOverlay}
                      onClick={(event) => this.handleClickAlbum(event, album)}
                    />
                  }
                  <CardMedia
                    className={classes.media}
                    image={process.env.PUBLIC_URL + '/image/' + album.coverArt}
                    component="img"
                  />

                  <CardContent className={classes.cardContent} >
                    <Typography className={classes.title} >
                      {album.albumTitle}
                    </Typography>
                    <Accordion elevation={0} className={classes.postCardList} >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={album.id}
                      >
                        <Typography className={classes.heading}>{album.albumTitle}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                          >
                            <TableBody>
                              {album.tracks && (album.tracks.map(index => {
                                //go check each song in render for matching url and album (for selection)
                                var isItemSelected = renderSelected(album.tracks.[selected], index.url, album.id);
                                const labelId = `enhanced-table-checkbox-${index}`;


                                return (
                                  <TableRow
                                    hover
                                    onClick={(event) => this.handleClickSong(event, album.tracks, index.url, album, album.albumTitle)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index.title}
                                    selected={isItemSelected}
                                    className={classes.root}
                                  >
                                    <TableCell width={25} className={classes.iconTableCell} >
                                      {isItemSelected && playing ? <PauseCircleOutlineIcon className={classes.playButton} />
                                        : <PlayCircleOutlineIcon className={classes.playButton} />}
                                    </TableCell>
                                    <TableCell id={labelId} scope="row" >
                                      {index.title}
                                    </TableCell>


                                  </TableRow>
                                );
                              }))}


                              {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>

                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
    );
  }
}


export default compose(
  withFirebase,
  withStyles(styles, { withTheme: true })
)(HomePage);