import styled from 'styled-components'


export const WaveformContianer = styled.div`
  display: flex;  
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  height: 100px;  width: 100%;
  background: transparent;
`;


export const Wave = styled.div`
  width: 100%;
  height: 90px;
`;
