import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';
import ROUTES from '../../constants/routes';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
  	marginTop: theme.spacing(3),
  	marginBottom: theme.spacing(3),
  },
  panel: {
  	[theme.breakpoints.up('md')]: {
  		width: 600,
  		margin: `${theme.spacing(4)}px auto`
  	},
  	[theme.breakpoints.down('sm')]: {
  		margin: `${theme.spacing(1)}px 
  		${theme.spacing(1)}px 
  		${theme.spacing(20)}px 
  		${theme.spacing(1)}px`
  	},
  }
});
 
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
 
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email, password } = this.state;
 
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.home.slug);
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    const { classes } = this.props;   
 
    return (
      <Box className={classes.panel} >
      	<h2>Sign In</h2>
        <form onSubmit={this.onSubmit}>
          <TextField id="email" label="Email"
            width="100%"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
          />
          <TextField id="password" label="Password"
            width="100%"
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
          />
          <Button 
            variant="contained" 
            disabled={isInvalid} 
            type="submit"
            className={classes.root}
          >
            Sign In
          </Button>
   
          {error && <p>{error.message}</p>}
        </form>
        <SignUpLink />
      </Box>
      
    );
  }
}
 
const SignInForm = compose(
	withStyles(styles),
  withRouter,
  withFirebase,
)(SignInFormBase);
 
export default SignInForm;
 
export { SignInForm };