import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from './components/Firebase';
import Drawer from './components/Drawer';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { withAuthentication } from './components/Session';
import * as Theme from './constants/theme';

const App = (props) => { 
	const authUser = props.authUser;
			return	(  
      <Router>
	        <ThemeProvider theme={ Theme.darkTheme }> 
	          <Drawer authUser={authUser} />
	        </ThemeProvider>
      </Router>  
    );
}
export default compose(
  withFirebase,
  withAuthentication
)(App);
