import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import ROUTES from "../../constants/routes";
import {
  Route,
  Switch,
  Link,
} from "react-router-dom";
import Navigation from "../Navigation";
import Audio from "../../components/Wavesurfer";
import HomePage from "../../pages/Home";
import { useTimer } from "use-timer";
import PreviewArea from "../../pages/PreviewArea";
import NavDialog from "components/NavDialog";

const drawerWidth = 240;
const drawerWidthRight = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(
      ["margin", "width"],
      {
        easing: theme.transitions.easing.sharp,
        duration:
          theme.transitions.duration
            .leavingScreen,
      }
    ),
    color: theme.palette.text.dark,
    backgroundColor: theme.palette.appBar.main,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(
      ["margin", "width"],
      {
        easing: theme.transitions.easing.easeOut,
        duration:
          theme.transitions.duration
            .enteringScreen,
      }
    ),
  },
  menuButton: {
    marginRight: 10,
  },
  topMenuContainer: {
    justifyContent: "flex-end",
  },
  topMenuItem: {
    flexBasis: "unset",
    maxWidth: "unset",
  },
  callTo: {},
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperRight: {
    width: drawerWidthRight,
    padding: 12,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create(
      "margin",
      {
        easing: theme.transitions.easing.sharp,
        duration:
          theme.transitions.duration
            .leavingScreen,
      }
    ),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create(
      "margin",
      {
        easing: theme.transitions.easing.easeOut,
        duration:
          theme.transitions.duration
            .enteringScreen,
      }
    ),
    marginLeft: 0,
  },
  logo: {
    maxWidth: "40px",
    transition: "none",
  },
  live: {
    maxWidth: "200px",
    marginLeft: "12px",
  },
  appBarBottom: {
    top: "auto",
    bottom: "0",
    height: "110px",
    backgroundColor:
      theme.palette.appBarBottom.main,
  },
  appBarBottomBox: {
    padding: theme.spacing(0),
  },
}));

function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const authUser = props.authUser;
  const [open, setOpen] = React.useState(false);
  const [track, setTrack] = React.useState("");
  const [playing, setPlaying] =
    React.useState(false);
  const [trackNumber, setTrackNumber] =
    React.useState(false);
  const [album, setAlbum] = React.useState(false);
  const [paywall, setPaywall] =
    React.useState(false);
  const [firstVisit, setFirstVisit] =
    React.useState(true);
  const [secretPreview, setSecretPreview] =
    React.useState(false);
  const endTime = 120;
  const {
    // eslint-disable-next-line
    time,
    start,
    pause,
    reset,
    status,
  } = useTimer({
    endTime,
    onTimeOver: () => {
      setPlaying(false);
      setPaywall(true);
    },
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const previewFunction = () => {
    setSecretPreview(!secretPreview);
  };

  const selectedTrack = (
    url,
    trackNumber,
    album,
    event
  ) => {
    // callback.
    setTrack(url);
    setTrackNumber(trackNumber);
    setAlbum(album);
    setFirstVisit(false);

    if (
      (props.authUser == null ||
        !props.authUser.roles["PAID"]) &&
      !secretPreview
    ) {
      reset();
      start();
    }

    if (
      track === url ||
      track === "" ||
      playing === false
    ) {
      setPlaying(!playing);
    } else {
      return;
    }
  };

  const waveClick = () => {
    if (
      (props.authUser == null ||
        !props.authUser.roles["PAID"]) &&
      !secretPreview
    ) {
      reset();
      start();
    }
    if (playing === false) {
      setPlaying(true);
    }
  };

  const nextTrack = () => {
    if (trackNumber < album.tracks.length - 1) {
      setTrack(
        album.albumTitle
          .replace(/\s+/g, "-")
          .toLowerCase() +
          "/" +
          album.tracks[trackNumber + 1].url
      );
      setTrackNumber(trackNumber + 1);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(
                classes.menuButton,
                open && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              className={classes.topMenuContainer}
            >
              {secretPreview && (
                <Grid
                  item
                  xs={6}
                  className={classes.topMenuItem}
                >
                  <Button
                    variant="text"
                    className={classes.callTo}
                    component={Link}
                    onClick={previewFunction}
                    to="/"
                  >
                    You are now in secret preview
                    mode. FYEO! Click to return to
                    normal mode
                  </Button>
                </Grid>
              )}
              {/* {!secretPreview && (
                <Grid item xs={6} className={classes.topMenuItem} > 
          			<Button variant="text" 
          			component={Link}
          			to={ ROUTES.signup.slug } >
          				Launch Special
          			</Button>
          		</Grid>)} */}
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={handleDrawerClose}
            >
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Navigation close={handleDrawerClose} />
        </Drawer>
        <main className={clsx(classes.content)}>
          <div className={classes.drawerHeader} />
          <Switch>
            <Route
              exact
              path={ROUTES.home.slug}
              render={(props) => (
                <HomePage
                  selectedTrack={selectedTrack}
                  playing={playing}
                  open={open}
                  trackNumber={trackNumber}
                  authUser={authUser}
                />
              )}
            />
            <Route
              exact
              path={ROUTES.secret.slug}
              render={(props) => (
                <PreviewArea
                  selectedTrack={selectedTrack}
                  playing={playing}
                  open={open}
                  trackNumber={trackNumber}
                  authUser={authUser}
                  previewFunction={
                    previewFunction
                  }
                />
              )}
            />
            <Route path={ROUTES.signup.slug}>
              {" "}
              {ROUTES.signup.component}{" "}
            </Route>
            <Route path={ROUTES.signin.slug}>
              {" "}
              {ROUTES.signin.component}{" "}
            </Route>
            <Route path={ROUTES.admin.slug}>
              {" "}
              {ROUTES.admin.component}{" "}
            </Route>
            <Route path={ROUTES.checkout.slug}>
              {" "}
              {ROUTES.checkout.component}{" "}
            </Route>
            <Route path={ROUTES.thankyou.slug}>
              {" "}
              {ROUTES.thankyou.component}{" "}
            </Route>
          </Switch>
        </main>
        <AppBar
          position="fixed"
          className={classes.appBarBottom}
        >
          <Box
            className={classes.appBarBottomBox}
          >
            <Audio
              nextTrack={nextTrack}
              playing={playing}
              url={
                process.env.PUBLIC_URL +
                "/audio/" +
                track
              }
              waveClick={waveClick}
              paywall={paywall}
              firstVisit={firstVisit}
            />
          </Box>
        </AppBar>
      </div>
      <NavDialog />
    </>
  );
}

export default PersistentDrawerLeft;
