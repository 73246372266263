import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkout from '../Checkout'
import CheckIcon from '@material-ui/icons/Check';
import Zoom from '@material-ui/core/Zoom';

const styles = theme => ({
  root: {
  	marginTop: theme.spacing(3),
  	marginBottom: theme.spacing(3),
  },
  panel: {
  	[theme.breakpoints.up('md')]: {
  		width: 600,
  		margin: `${theme.spacing(4)}px auto ${theme.spacing(20)}px auto`
  	},
  	[theme.breakpoints.down('sm')]: {
  		margin: `${theme.spacing(1)}px 
  		${theme.spacing(1)}px 
  		${theme.spacing(20)}px 
  		${theme.spacing(1)}px`
  	},
  }
});

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    // const { username, email, passwordOne } = this.state;
    const { firstName, lastName, email, passwordOne } = this.state;
    const roles = {
      SUBSCRIBER: 'SUBSCRIBER',
    };

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            firstName,
            lastName,
            email,
            roles,
          })
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(ROUTES.checkout.slug);
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
 
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
 
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
 
  render() {
    const {
      firstName,
      lastName,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === '' ||
    email === '' ||
    firstName === '' ||
    lastName === '';

    const { classes } = this.props;   

    return (

	    <AuthUserContext.Consumer>
	    	{ authUser => 
	    	<div>
				  <Box className={classes.panel} >
				      <h2>Become a Supporter</h2>
				      <p>For a limited time only - 6 bucks gets you a <b>lifetime</b> membership:</p>
				      <ul>
				      	<li>
				      		continous playback on the site
				      	</li>
				      	<li>
				      		download links for all releases
				      	</li>
				      	<li>
				      		access to all live streaming
				      	</li>
				      </ul>

		      <form onSubmit={this.onSubmit}>
		        <TextField id="firstName" 
		        		label={authUser ? authUser.firstName : "First Name"}
		            width="100%"
		            name="firstName"
		            value={firstName}
		            onChange={this.onChange}
		            disabled={ authUser !== null }
		            type="text"
		          />
		        <TextField id="lastName" 
		        		label={authUser ? authUser.lastName : "Last Name"}
		            width="100%"
		            name="lastName"
		            value={lastName}
		            onChange={this.onChange}
		            disabled={ authUser !== null }
		            type="text"
		          />
		        <TextField id="email" 
		        		label={authUser ? authUser.email : "Email"}	      
		            width="100%"
		            name="email"
		            value={email}
		            onChange={this.onChange}
		            disabled={ authUser !== null }
		            type="text"
		          />
		        <TextField id="passwordOne" 
		        		label={authUser ? "*******" : "Password"}	  
		            width="100%"
		            name="passwordOne"
		            value={passwordOne}
		            onChange={this.onChange}
		            disabled={ authUser !== null }
		            type="password"
		          />
		        <TextField id="passwordTwo" 
		        		label={authUser ? "*******" : "Verify Password"}	 
		            width="100%"
		            name="passwordTwo"
		            value={passwordTwo}
		            onChange={this.onChange}
		            disabled={ authUser !== null }
		            type="password"
		            style={{marginBottom: '24px' }}
		          />
		        	{authUser === null ? 
			        		<Button 
			            variant="outlined" 
			            disabled={ isInvalid || authUser !== null } 
			            type="submit"
			            className={classes.root}
				          > Next </Button> 
			        		:
			        		 <Zoom in={true}>
			        		 	<Grid container>
			        		 		<Grid item>
			        		 			<CheckIcon />
			        		 		</Grid>
			        		 		<Grid item style={{paddingLeft: 8}} >
			        		 			Please enter your card info below.
			        		 		</Grid>
			        		 	</Grid>
			        		 </Zoom> }
		          {error && <p>{error.message}</p>}
	   	      </form>
	   	      	{authUser !== null ? 
				          <Checkout authUser={authUser} />
				          :
				          ""
		   	      	}
					  </Box>		   	      	
	          </div>
	        }
	   			
	    </AuthUserContext.Consumer>
    );
  }
}
 
const SignUpLink = () => (
  <p>
    Don't have an account? <Link href={ROUTES.signup.slug} color="secondary">
    Sign Up.
  </Link>
  </p>
);

const SignUpForm = compose(
	withStyles(styles),
  withRouter,
  withFirebase,
)(SignUpFormBase);
 
export default SignUpForm;
 
export { SignUpForm, SignUpLink };