import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import config from '../../constants/firebaseConfig'

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }
  // *** Auth API ***
 
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
 
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


// *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            // console.log(authUser.firstName + " " + authUser.lastName);
            next(authUser);
            // return (authUser.firstName + " " + authUser.lastName );
          });
      } else {
        fallback();
      }
    });

// *** User API ***

  user = (uid) => 
    this.db.collection("users").doc(uid);

  users = () => 
    this.db.collection("users");

// *** Database API ***
 
  doCreateField = (name, value) => 
    this.db.collection("events").doc("Test Event").set(name, value);

  getCollection = (name) =>
    this.db.collection(name); 

  // careful with this, there's a limit to how many can be deleted at once

  clearCollection = (collection) =>
    this.db.collection(collection)
    .get()
    .then(res => {
      res.forEach(element => {
        element.ref.delete();
      });
    }); 

// *** Storage API ***




};

export default Firebase;