import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
// import { withAuthorization } from '../Session';
import { withStyles } from '@material-ui/core/styles';
// import * as ROLES from '../../constants/roles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {},
  buttonContainer: {
  	margin: '5px 10px',
  	padding: '5px 5px'
  },
  downloadsHeader: {
  	fontSize: '14px',
  	margin: `${theme.spacing(2)}px`
  },
 });

class DownloadLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }	

  handleClick = (event) => {
     const storage = this.props.firebase.storage;
     const name = this.props.file;
     const pathReference = storage.ref(name);
     	pathReference.getDownloadURL()
     	.then((url) => {
      // `url` is the download URL for 'images/stars.jpg'
  		// console.log(url);
      // This can be downloaded directly:
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
        //Create an `a` tag (since it has an `href` and a `download` attribute) 
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();                            //Simulates a click event
        // var blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
    })
    .catch((error) => {
      console.log(error);
    });
  }

	render() {
	  const { classes, file } = this.props;
		return (
			<div>
			<Typography variant="h3" className={classes.downloadsHeader} >
				Downloads
			</Typography>
			<Divider light={true}  />
			<div className={classes.buttonContainer} >
				<Button onClick={this.handleClick} >{file}</Button>
			</div>

			</div>
		)
	}
};


// const condition = authUser =>
//   true;

export default compose(
	withStyles(styles),
	withFirebase,
)(DownloadLink);