import React from 'react';
import { compose } from 'recompose';
import ReactPlayer from 'react-player/lazy'; 
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chat from '../components/Chat';
import { withAuthorization } from '../components/Session'; 
import * as ROLES from '../constants/roles'
import { withFirebase } from '../components/Firebase';
import SurveyItemsPlayer from '../components/SurveyItems/SurveyItemsPlayer'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

  },
  upperBox: {
    padding: theme.spacing(1),
    height:'100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    // backgroundColor: theme.palette.panels.upperBox
  },
  lowerBox: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:'100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: '1px solid ' + theme.palette.primary.light ,
    // backgroundColor: theme.palette.panels.lowerBox
  },
  gridContainer: {
    padding: 0,
    maxHeight: '20vh'
  },
  chatGridItem: {
    minHeight: 'calc(100vh - 64px)',
    padding: 0,
    overflow: 'auto',
    width:'100%',
  },
  chatPaper: {
    padding: "0 20px 20px 20px",
    borderRadius: 0,
    minHeight: 'calc(100vh - 64px)',
    // backgroundColor: theme.palette.panels.rightBox
  }
}));

export function ResponsivePlayer(props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const handleChange = () => {
      setChecked((prev) => !prev);
    };

    return (
      <Grid container >
        <Grid item md={9}>

            <Box 
              display='flex'
              flexDirection='column'
              css={{height: 'calc(100vh - 64px)'}}
              justifyContent="center"
            >
              <Box flex='1 1 50vh' >
                <Fade in={checked} timeout={1000} >
	                <div className={classes.upperBox}>
	                 <ReactPlayer
	                   onReady={handleChange} 
	                   className='react-player'
	                   url='https://vimeo.com/459050588'
	                   width="100%"
	                   height="100%"
	                    config={{
	                        vimeo: {
	                          playerOptions: { playsinline: true }
	                        }
	                      }}           
	                   controls
	                 /> 
	                </div>
                </Fade>
              </Box>
              <Box flex='1 1 auto' alignItems="center" style={{maxHeight: '18vh'}}>
                <Box className={classes.lowerBox}>
                  <Box>
                  	<SurveyItemsPlayer /> 
                  </Box>
                </Box>   
              </Box> 
            </Box>

        </Grid>
        <Grid item md={3} className={classes.chatGridItem} >
          <Fade in={checked}>
          <Paper className={classes.chatPaper} >
            <Chat />
          </Paper>
          </Fade>
        </Grid>  
      </Grid>   
    );
};

//Authorization
const condition = authUser =>
 (authUser && !!authUser.roles[ROLES.SUBSCRIBER]) || (authUser && !!authUser.roles[ROLES.ADMIN]);

export default compose(
  withAuthorization(condition),
  withFirebase,
)(ResponsivePlayer);